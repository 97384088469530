module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"short_name":"AUUC","start_url":"/","background_color":"#1e73be","theme_color":"#1e73be","display":"browser","icon":"src/images/auuc-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ddaa501051bb55c4a563fc62f2810772"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
